<template>
  <div class="footer-fluid">
    <div class="box-footer">
      <div class="container">
        <div class="footer">
          <div class="footer__form">
            <div class="box__link-item">
              <h4 class="item-title">
                {{ $t('Subscribe to our newsletter') }}
              </h4>
              <div class="item-content col-form-newletter">
                <form
                  class="form"
                  @submit="submit"
                >
                  <SfInput
                    v-model="email"
                    :label="$t('Insert your email address')"
                    name="email"
                    class="
                      form__element form__element--half form__element--half-even
                    "
                    required
                    :valid="emailBlur || validEmail(email)"
                    :error-message="$t('Please enter a valid email address.')"
                    @blur="emailBlur = false"
                  />
                  <div class="form__action">
                    <SfButton
                      type="submit"
                      :disabled="!validEmail(email)"
                      @click="showPopup(email)"
                      @click.prevent="submit"
                    >
                      {{ $t('Subscribe') }}
                    </SfButton>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="footer__list-links">
            <div class="box__link-item contatti">
              <h4 class="item-title">
                {{ $t('Contacts Us') }}
              </h4>

              <div class="item-content">
                <div
                  class="link-wrap"
                >
                  <a
                    :href="'mailto:' + contatti[0]"
                    class="link-item"
                    target="_blank"
                  >{{ contatti[0] }}</a>
                </div>
                <div
                  class="link-wrap"
                >
                  <a
                    :href="'tel:' + contatti[1]"
                    class="link-item"
                    target="_blank"
                  >{{ contatti[1] }}</a>
                </div>
              </div>
            </div>

            <div
              v-for="(section, indexFooterLink) in footerLink.sections"
              :key="'footerLink-' + indexFooterLink"
              class="box__link-item servizi"
            >
              <h4 class="item-title">
                {{ section.label }}
              </h4>

              <div class="item-content">
                <div
                  v-for="(item, indexSection) in section.links"
                  :key="'section-' + indexSection"
                  class="link-wrap"
                >
                  <nuxt-link
                    v-if="!item.url.includes('http')"
                    :target="item.target"
                    :to="{ path: cleanPath(item.url, locale)}"
                    class="link-item"
                  >
                    {{ item.label }}
                  </nuxt-link>
                  <a
                    v-else-if="item.url.includes('http')"
                    :href="item.url"
                    :target="item.target"
                    class="link-item"
                  >
                    {{ item.label }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-text-bottom">
          <div class="text__left">
            <p>
              {{ $t('© Lazzari. All right reserved. VAT. IT02192410237') }} ·<br
                class="mobile-only"
              >
            </p>
            <p
              v-for="(service, indexFooterBottomLink) in footerBottomLink.links"
              :key="'indexFooterBottomLink-' + indexFooterBottomLink"
              class="service-footer"
            >
              <nuxt-link
                :to="service.url"
                :target="service.target"
              >
                {{ service.label }}
              </nuxt-link>
              ·
            </p>
          </div>
        </div>
      </div>
      <transition name="sf-fade">
        <NewsletterForm
          v-if="isOpenPopup"
          :email="email"
          @closePopup="closePopup"
          @showToast="showToast"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { SfInput, SfButton } from '@storefront-ui/vue';
import { contentGetters, useContent } from '@vue-storefront/gemini';
import { useFetch, ref } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import { useI18n } from '../helpers/hooks/usei18n';
import NewsletterForm from './NewsletterForm.vue';

export default {
  components: {
    SfButton,
    SfInput,
    NewsletterForm,
  },
  setup() {
    const translate = useI18n();
    const { locale } = translate;

    const identifier = `footer-links-${locale}`;
    const identifierServiceMenu = `services-menu-${locale}`;
    const identifierFooterBottom = `footer-bottom-links-${locale}`;
    const identifierMyNewsLetter = `my-newsletter-${locale}`;
    const identifierMyProfile = `profile-${locale}`;
    const identifierSize = `size-chart-${locale}`;
    const preHeaderMessage = `pre-header-${locale}`;
    const identifierMenuShop = `store-menu-${locale}`;
    const identifierStorySlide = `storySlide-${locale}`;
    const identifierStory = `StoryHeading-${locale}`;
    const identifierContact = `storeHeading-${locale}`;
    const identifierContactStore = `store-${locale}`;
    const identifierInstagramStory = `instagramStory-${locale}`;
    const identifierRelatedProductsCarouselHome = `relatedProductsCarouselHome-${locale}`;
    const identifierShippingAndReturn = `shipping-return-${locale}`;

    const { blocks, loadBlocks } = useContent(`cmsBlockFooter-${locale}`);

    const { send: sendNotification } = useUiNotification();

    const footerLink = ref({
      sections: [],
    });
    const footerBottomLink = ref({
      sections: [],
    });

    const showToast = (errorMessage) => {
      if (errorMessage) {
        sendNotification({
          id: Symbol('need_help_form_error'),
          message: translate.t('An error occurred while subscribing to the newsletter. Did you already subscribe with this email?'),
          type: 'error',
          icon: 'error',
          persist: true,
          title: translate.t('An error occurred'),
        });
        return;
      }
      sendNotification({
        id: Symbol('need_help_form_success'),
        message: translate.t('You successfully subscribed to the newsletter.'),
        type: 'success',
        icon: 'success',
        persist: false,
        title: translate.t('Subscribed'),
      });
    };

    useFetch(async () => {
      await loadBlocks({
        identifiers: [
          identifier,
          identifierServiceMenu,
          identifierFooterBottom,
          identifierMyNewsLetter,
          identifierMyProfile,
          identifierSize,
          preHeaderMessage,
          identifierMenuShop,
          identifierStorySlide,
          identifierStory,
          identifierContact,
          identifierContactStore,
          identifierInstagramStory,
          identifierRelatedProductsCarouselHome,
          identifierShippingAndReturn,
        ],
      });
      const blocksContent = contentGetters.getCmsBlockContent(blocks.value);
      // check if blocks contains the identifier
      footerLink.value = blocksContent[identifier] ? blocksContent[identifier] : {
        sections: [],
      };
      footerBottomLink.value = blocksContent[identifierFooterBottom] ? blocksContent[identifierFooterBottom] : {
        sections: [],
      };
    });

    return {
      blocks,
      identifier,
      identifierServiceMenu,
      identifierFooterBottom,
      identifierMyNewsLetter,
      identifierMyProfile,
      identifierSize,
      preHeaderMessage,
      identifierMenuShop,
      identifierStorySlide,
      identifierStory,
      identifierContact,
      identifierContactStore,
      identifierInstagramStory,
      identifierRelatedProductsCarouselHome,
      identifierShippingAndReturn,
      locale,
      footerLink,
      footerBottomLink,
      showToast,
    };
  },
  data() {
    return {
      contatti: ['info@lazzarionline.com', '+39 045 7614582'],
      isMobile: false,
      desktopMin: 1024,
      valid: false,
      submitted: false,
      email: '',
      emailBlur: true,
      message: '',
      messageBlur: true,
      content: {},
      serviceContent: {},
      isOpenPopup: false,
      isShowToast: false,
    };
  },
  methods: {
    validate() {
      this.emailBlur = false;
      this.messageBlur = false;
      if (this.validEmail(this.email)) {
        this.valid = true;
      }
    },
    submit() {},
    validEmail(email) {
      const regex = /(.+)@(.+){2,}\.(.+){2,}/;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return regex.test(email.toLowerCase());
    },
    showPopup(email) {
      if (this.validEmail(email)) {
        this.isOpenPopup = true;
      }
    },
    closePopup() {
      this.isOpenPopup = false;
    },
    cleanPath(urlPath, locale) {
      let path = '';
      if (urlPath === '/') {
        path = (`${urlPath}${locale}`);
      } else if (urlPath !== '/' && urlPath.charAt(0) === '/') {
        path = urlPath.includes(locale) ? urlPath : (`/${locale}${urlPath}`);
      }
      return path;
    },
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  bottom: 0;
  right: 5px;
  min-width: 420px;
  display: flex;
  background: #fff;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 7px 0px #777;
  animation: showToast 4s infinite;
  z-index: 1000;
  .icon-toast {
    margin-right: 8px;
  }

  &::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    background: #3c763d;
    display: block;
    content: '';
  }
}

@keyframes showToast {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.8;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

$color-text-primary: #0b0e0c;
$font-spartan: 'Spartan';

.footer-fluid {
  position: relative;
  padding-top: 47px;
}

.box-footer {
  max-width: 1414px;
  margin: 0 auto;
  padding: 0 84px;

  @media (max-width: 1024px) {
    margin-top: 60px;
    position: relative;
    padding: 0 24px;
  }
}

.footer__form {
  .item-title {
    color: $color-text-primary;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--base);
    font-family: $font-spartan;
    padding: 0;
    display: block;
    margin-bottom: 8px;
    text-align: left;
    text-transform: uppercase;
    @include for-mobile {
      margin-bottom: 0;
      font-size: var(--font-size--xs);
      color: #000000;
      font-weight: 600;
    }
  }
}

.col-form-newletter {
  min-width: 284px;
  @include for-mobile {
  }

  .form {
    .sf-input__wrapper input[name='email'] {
      color: $color-text-primary;
      box-shadow: none;
      border-bottom: 1px solid #000;
      font-size: var(--font-size--sm);
      font-weight: var(--font-weight--medium);
      outline: none;
      padding: 18px 0;
      height: 45px;
      padding-bottom: 0px;
      @include for-mobile {
        color: #5e6770;
        font-size: 16px;
      }
    }

    @include for-mobile {
      margin-bottom: 20px;
    }
  }

  #email {
    font-family: 'Spartan';
  }

  .sf-input__label {
    color: #0b0e0c;
    font-size: var(--font-size--sm);
    font-weight: var(--font-weight--medium);
    font-family: $font-spartan;
    opacity: 0.7;
    @include for-mobile {
      color: #5e6770;
      font-size: var(--font-size--xs);
      font-weight: var(--font-weight--medium);
    }
  }

  .sf-input__error-message {
    font-family: var(--font-family--primary);
    margin-bottom: 2px;
  }
}

.footer .form button[type='submit'] {
  display: block;
  &:active {
    box-shadow: none;
    text-decoration: none;
    background: #222a24;
  }
}

.footer__list-links {
  display: flex;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 37px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  width: calc(75% - 127px);

  .box__link-item {
    @include for-desktop {
      margin-left: auto;
    }
  }

  @include for-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    justify-items: baseline;

    .contatti {
      order: 4;
    }

    .servizi {
      order: 2;
    }

    .negozi {
      order: 3;
    }

    .social {
      order: 1;
    }
  }

  .box__link-item {
    .item-title {
      color: $color-text-primary;
      font-weight: var(--font-weight--bold);
      font-size: var(--font-size--base);
      font-family: $font-spartan;
      padding: 0;
      display: block;
      margin-bottom: 33px;
      text-align: left;
      text-transform: uppercase;
      @include for-mobile {
        margin-bottom: 24px;
        font-size: var(--font-size--xs);
        color: #000000;
        font-weight: 600;
      }
    }

    .link-wrap {
      .link-item {
        display: block;
        color: $color-text-primary;
        text-decoration: unset;
        font-size: var(--font-size--sm);
        font-weight: var(--font-weight--medium);
        font-family: $font-spartan;

        &:hover {
          color: #000000;
          text-decoration: underline;
        }

        @include for-mobile {
          color: #5e6770;
          font-size: var(--font-size--xs);
        }
      }
      p.link-item {
        margin: 0;
      }

      & + .link-wrap {
        margin-top: 7px;
        @include for-mobile {
          margin-top: 13px;
        }
      }
    }
  }
}

.footer-text-bottom {
  align-items: center;
  background-color: var(--c-white);
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-top: 82px;
  padding-bottom: 27px;
  @include for-mobile {
    display: block;
    padding-top: 32px;
    padding-bottom: 29px;
  }

  .service-footer {
    text-underline: none;
  }

  p {
    color: $color-text-primary;
    font-family: var(--font-family--secondary);
    font-size: var(--font-size--xs);
    font-weight: var(--font-weight--medium);
    font-family: $font-spartan;
    margin: 0;
    display: inline-block;

    @include for-mobile {
      color: #666666;
      font-size: 9px;
    }
  }

  a {
    color: $color-text-primary;
    margin: 5px;

    &:hover {
      color: #000000;
    }
  }

  .text-right {
    @include for-mobile {
      margin-top: 27px;
    }
  }

  .mobile-only {
    @include for-desktop {
      display: none;
    }
  }
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .footer__form {
    width: 25%;
    margin-right: 127px;
    @include for-mobile {
      width: 100%;
    }
  }

  @include for-desktop {
    margin-bottom: 0;
    padding: 0;
  }

  @include for-mobile {
    display: block;
    margin-bottom: 0;
  }

  &__socials {
    display: flex;
    justify-content: space-between;
    margin: 0 auto var(--spacer-lg);
    padding: var(--spacer-base) var(--spacer-xl);
    @include for-desktop {
      justify-content: flex-start;
      padding: var(--spacer-xs) 0;
      margin: 0 auto;
    }
  }

  &__social-image {
    margin: 0 var(--spacer-2xs) 0 0;
  }
}

.sf-footer {
  @include for-desktop {
    background-color: var(--c-white);
    padding-bottom: 0;
    margin-top: 0;
  }

  &__container {
    margin: var(--spacer-sm);
    @include for-desktop {
      max-width: 69rem;
      margin: 0 auto;
    }
  }

  .sf-footer__container {
    display: flex;
    flex-direction: inherit;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
  }

  .sf-menu-item__label {
    color: $color-text-primary;
    font-size: var(--font-size--sm);
  }

  @media (max-width: 520px) {
    .box-footer {
      margin: 0;
    }
  }
}
</style>
