<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div
      class="sf-header-custom_container"
      :class="{ 'header--sticky': checkSticky }"
    >
      <div
        class="sf-header--has-mobile-search"
        :class="{ 'header-on-top': isSearchOpen }"
      >
        <!-- TODO: add mobile view buttons after SFUI team PR -->
        <div class="sf-header-custom">
          <div class="sf-header__item_left">
            <SfButton
              v-e2e="'app-header-menu'"
              class="sf-button--pure sf-header__action_menu"
              data-testid="menuIcon"
              aria-label="Menu"
              @click="toggleBodyClass('addClass', 'open_menu')"
            >
              <nuxt-img
                :src="
                  require('../assets/images/header/Lazzari-Burger-menu.svg')
                "
                class="sf-header_menu-image"
                alt="Menu"
                format="webp"
                width="25"
                height="16"
                preload
                loading="lazy"
              />
            </SfButton>
            <nuxt-link
              :to="localePath('/')"
              class="sf-header__logo"
            >
              <img
                :src="require('../assets/images/header/Logo_Sito.png')"
                class="sf-header__logo-image"
                alt="Logo"
                height="26"
                width="187"
              />
            </nuxt-link>
          </div>
          <div class="sf-header__item_right">
            <SearchBarCustom
              ref="searchBarRef"
              v-click-outside="closeSearch"
              :placeholder="title.search"
              aria-label="Search"
              class="sf-header__search sf-search-bar--no-icon"
              :value="term"
              @keydown.enter="handleSearch($event)"
              @focus="isSearchOpen = false"
              @keydown.esc="closeSearch"
            />
            <LocaleSelector />
            <div class="sf-header__icons">
              <nuxt-link
                :to="localePath('/contact-us-v2')"
                class="sf-header__mail item_icon"
              >
                <nuxt-img
                  :src="require('../assets/images/header/Lazzari-Contatti.svg')"
                  alt="Mail"
                  class="sf-header__mail-image"
                  width="33"
                  height="24"
                  format="webp"
                  preload
                  loading="lazy"
                />
              </nuxt-link>
              <SfButton
                v-e2e="'app-header-wishlist'"
                class="sf-button--pure sf-header__wishlist item_icon"
                data-testid="wishlistIcon"
                aria-label="Wishlist"
                @click="toggleWishlistSidebar"
              >
                <SfIcon
                  v-if="wishlistItems"
                  icon="heart_fill"
                  size="lg"
                  data-test="sf-wishlist-icon"
                />
                <SfIcon
                  v-else
                  class="wishlist-empty-icon"
                >
                  <nuxt-img
                    width="28"
                    height="28"
                    src="/icons/wishlist-heart.svg"
                    alt="empty wishlist icon"
                  />
                </SfIcon>
              </SfButton>
              <SfButton
                v-e2e="'app-header-account'"
                class="sf-button--pure sf-header__user item_icon"
                data-testid="accountIcon"
                aria-label="Account"
                @click="handleAccountClick"
              >
                <nuxt-img
                  preload
                  loading="lazy"
                  :src="require('../assets/images/header/Lazzari-Account.svg')"
                  alt="User"
                  class="sf-header__user-image"
                  format="webp"
                  width="28"
                  height="28"
                />
              </SfButton>

              <SfButton
                v-e2e="'app-header-cart'"
                class="sf-button--pure sf-header__cart item_icon"
                aria-label="Toggle cart sidebar"
                @click="toggleCartSidebar"
              >
                <nuxt-img
                  preload
                  loading="lazy"
                  :src="require('../assets/images/header/Logo_Carrello.svg')"
                  alt="Cart"
                  class="sf-header__cart-image"
                  width="28"
                  height="28"
                  format="webp"
                />
                <SfBadge
                  v-if="cartTotalItems"
                  class="sf-badge--number cart-badge"
                >
                  {{ cartTotalItems }}
                </SfBadge>
              </SfButton>
            </div>
          </div>
        </div>
        <client-only>
          <SearchResults
            :visible="isSearchOpen"
            :result="result"
            :value="term"
            @close="closeSearch"
            @removeSearchResults="removeSearchResults"
          />
          <SfOverlay :visible="isSearchOpen" />
        </client-only>
      </div>
    </div>

    <div
      id="mega_menu"
      :class="{ 'header--sticky': checkSticky }"
    >
      <div class="mega_top">
        <div class="close_menu">
          <SfButton
            v-e2e="'app-header-menu'"
            class="sf-button--pure sf-header__action_menu"
            data-testid="menuIcon"
            aria-label="Menu"
            @click="toggleBodyClass('removeClass', 'open_menu')"
          >
            <nuxt-img
              :src="
                require('../assets/images/header/Lazzari-Burger-menu-close.svg')
              "
              alt="Menu"
              class="sf-header_menu-image"
              :width="19"
              :height="19"
            />
          </SfButton>
        </div>
        <SearchBarCustom
          ref="searchBarRef"
          v-click-outside="closeSearch"
          :placeholder="title.search"
          aria-label="Search"
          class="sf-header__search sf-search-bar--no-icon"
          :value="term"
          @keydown.enter="handleSearch($event)"
          @focus="isSearchOpen = false"
          @keydown.esc="closeSearch"
        />
        <LocaleSelector />
      </div>
      <SfAccordion
        open="Shop"
        :multiple="true"
        transition=""
        show-chevron
      >
        <template v-if="menu.uid">
          <template v-for="menuItem in menu.items">
            <template v-if="menuItem.children.length === 0">
              <div
                :key="menuItem.uid"
                class="sf-accordion-item"
              >
                <nuxt-link :to="menuItem.link">
                  <span
                    class="sf-accordion-item__header"
                    @click="toggleBodyClass('removeClass', 'open_menu')"
                  >
                    {{ menuItem.label }}
                  </span>
                </nuxt-link>
              </div>
            </template>
            <template v-else>
              <SfAccordionItemCustom
                :key="menuItem.uid"
                :header="menuItem.label"
              >
                <SfList>
                  <SfListItem
                    v-for="child in menuItem.children"
                    :key="child.uid"
                  >
                    <nuxt-link
                      :to="{ path: child.link }"
                      class="link-item"
                    >
                      <span
                        @click="toggleBodyClass('removeClass', 'open_menu')"
                      >
                        {{ child.label }}
                      </span>
                    </nuxt-link>
                  </SfListItem>
                </SfList>
              </SfAccordionItemCustom>
            </template>
          </template>
        </template>
      </SfAccordion>
    </div>
    <div
      id="overlay_menu"
      @click="toggleBodyClass('removeClass', 'open_menu')"
    />
    <CustomerGtm
      v-if="isMounted && isLoggedIn !== undefined"
      :is-logged="isLoggedIn"
      :customer-data="{ user }"
    />
  </div>
</template>
<script>
import {
  SfIcon,
  SfButton,
  SfBadge,
  SfOverlay,
  SfAccordion,
  SfList,
} from '@storefront-ui/vue';

import {
  cartGetters,
  categoryGetters,
  wishlistGetters,
  contentGetters,
  useCart,
  useCategory,
  useCategorySearch,
  useContent,
  useFacet,
  useUser,
  useMenu,
} from '@vue-storefront/gemini';
import {
  computed,
  ref,
  onBeforeUnmount,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  // useContext,
} from '@nuxtjs/composition-api';
import { isEmpty } from 'lodash';
import { onSSR } from '@vue-storefront/core';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives/click-outside/click-outside-directive.js';
import {
  mapMobileObserver,
  unMapMobileObserver,
} from '@storefront-ui/vue/src/utilities/mobile-observer.js';
import debounce from 'lodash.debounce';
import { useUiHelpers, useUiState, useWishlist } from '~/composables';
import LocaleSelector from '~/components/LocaleSelector.vue';
import SearchResults from '~/components/SearchResults.vue';
// import StoreSwitcher from '~/components/StoreSwitcher.vue';
import SearchBarCustom from '~/components/SearchBarCustom.vue';
import CustomerGtm from '~/components/Gtm/CustomerGtm.vue';
import SfAccordionItemCustom from './SfAccordionItemCustom.vue';

import { useI18n } from '../helpers/hooks/usei18n';

export default defineComponent({
  components: {
    LocaleSelector,
    SfIcon,
    SfButton,
    SfBadge,
    SearchResults,
    SfOverlay,
    SfAccordion,
    SfList,
    SfAccordionItemCustom,
    SearchBarCustom,
    CustomerGtm,
  },
  directives: { clickOutside },
  props: {
    checkSticky: Boolean,
  },
  setup() {
    const { wishlist } = useWishlist();
    const router = useRouter();
    const { toggleCartSidebar, toggleLoginModal, toggleWishlistSidebar } = useUiState();
    const { setTermForUrl, getFacetsFromURL, getAgnosticCatLink } = useUiHelpers();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { cart, load: loadCart } = useCart();
    const { menu, search: searchMenu } = useMenu('common');
    const { app } = useContext();
    const {
      result: searchResult,
      search: productsSearch,
      // loading: productsLoading,
    } = useFacet('AppHeader:Products');
    const { result: categories, search: categoriesSearch } = useCategorySearch(
      'AppHeader:Categories',
    );
    const { search: categoriesListSearch } = useCategory(
      'AppHeader:CategoryList',
    );

    const { locale } = useI18n();
    const {
      blocks,
      // error,
      // loadBlocks,
      // loading,
    } = useContent(`cmsBlockFooter-${locale}`);
    const identifierServiceMenu = `services-menu-${locale}`;
    const identifierMenuShop = `store-menu-${locale}`;

    const term = ref(getFacetsFromURL().term);
    const isSearchOpen = ref(false);
    const isSelected = ref(false);
    const searchBarRef = ref(null);
    const result = ref(null);
    const isMounted = ref();

    const cartTotalItems = computed(() => {
      const count = cartGetters.getTotalItems(cart.value);
      return count ? count.toString() : null;
    });

    const wishlistItems = computed(() => {
      let items = [];
      if (!isEmpty(wishlist.value)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        items = wishlistGetters.getItems(wishlist.value);
      }
      return items.length;
    });

    const accountIcon = computed(() => (isAuthenticated.value ? 'profile_fill' : 'profile'));

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localePath('/my-account'));
      } else {
        toggleLoginModal();
      }
    };

    onSSR(async () => {
      await Promise.all([
        searchMenu({ code: 'mainmenu' }),
        categoriesListSearch({
          pageSize: 20,
        }),
      ]);
    });

    const closeSearch = () => {
      if (!result) {
        if (!isSearchOpen.value) return;

        term.value = '';
        isSearchOpen.value = false;
      }
    };

    const handleBodyClass = (addRemoveClass, className) => {
      const elCustom = document.body;

      if (addRemoveClass === 'addClass') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        elCustom.classList.add(className);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        elCustom.classList.remove(className);
      }
    };

    const handleSearch = debounce(async (paramValue) => {
      handleBodyClass('addClass', 'loading_search');
      term.value = !paramValue.target ? paramValue : paramValue.target.value;

      await Promise.all([
        productsSearch({
          itemsPerPage: 12,
          term: term.value,
        }),
        categoriesSearch({ filters: { name: { match: `${term.value}` } } }),
      ]);

      isSearchOpen.value = true;
      result.value = {
        products: searchResult.value?.data?.items,
        categories: categories.value.map((element) => categoryGetters.getCategoryTree(element)),
      };
      handleBodyClass('removeClass', 'open_menu');
      handleBodyClass('removeClass', 'loading_search');
    }, 300);

    const isMobile = computed(() => mapMobileObserver().isMobile.get());

    const closeOrFocusSearchBar = () => {
      if (isMobile.value) {
        return closeSearch();
      }
      term.value = '';
      return searchBarRef.value.$el.children[0].focus();
    };

    const removeSearchResults = () => {
      result.value = null;
      term.value = '';
      isSearchOpen.value = false;
    };

    onBeforeUnmount(() => {
      unMapMobileObserver();
    });

    onMounted(async () => {
      await Promise.all([loadUser(), loadCart()]).catch((error) => {
        console.error(error);
      });
      isMounted.value = true;
    });

    const isLoggedIn = computed(() => isAuthenticated.value);

    return {
      wishlistItems,
      accountIcon,
      cartTotalItems,
      closeOrFocusSearchBar,
      closeSearch,
      getAgnosticCatLink,
      handleAccountClick,
      handleSearch,
      isAuthenticated,
      isMobile,
      isSearchOpen,
      isSelected,
      removeSearchResults,
      result,
      searchBarRef,
      setTermForUrl,
      term,
      toggleCartSidebar,
      blocks,
      identifierServiceMenu,
      identifierMenuShop,
      menu,
      isLoggedIn,
      user,
      isMounted,
      toggleWishlistSidebar,
      wishlist,
    };
  },
  data() {
    return {
      title: {
        shop: 'Shop',
        services: 'Need Help?',
        search: 'Search',
        visit: 'Visit us',
        svgPath:
          'M16.5154 2.26988C16.1046 1.86729 15.6168 1.54793 15.08 1.33004C14.5432 1.11215 13.9678 1 13.3868 1C12.8057 1 12.2303 1.11215 11.6935 1.33004C11.1567 1.54793 10.6689 1.86729 10.2582 2.26988V2.26988L9.40563 3.10501L8.55311 2.26988C7.72335 1.45706 6.59796 1.00042 5.42451 1.00042C4.25106 1.00042 3.12567 1.45706 2.29591 2.26988C1.46615 3.08271 1 4.18513 1 5.33463C1 6.48414 1.46615 7.58656 2.29591 8.39939L3.14843 9.23451L9.40563 15.364L15.6628 9.23451L16.5154 8.39939C16.9263 7.99699 17.2524 7.51921 17.4748 6.99335C17.6972 6.46749 17.8117 5.90385 17.8117 5.33463C17.8117 4.76542 17.6972 4.20178 17.4748 3.67592C17.2524 3.15006 16.9263 2.67228 16.5154 2.26988V2.26988Z',
      },
    };
  },
  computed: {
    servicesLink() {
      return computed(() => contentGetters.getCmsBlockContent(this.blocks))
        .value[this.identifierServiceMenu];
    },
    shopLink() {
      return computed(() => contentGetters.getCmsBlockContent(this.blocks))
        .value[this.identifierMenuShop];
    },
  },
  // Just in case you like to do it when page or component is mounted or destroyed.
  destroyed() {
    this.toggleBodyClass('removeClass', 'open_menu');
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      const elHtml = document.querySelectorAll('html')[0];
      const layoutElement = document.querySelector('#layout').offsetTop;
      const menuElement = document.querySelector('#mega_menu');

      if (addRemoveClass === 'addClass') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        el.classList.add(className);
        elHtml.classList.add(`${className}_html`);
        if (className === 'open_menu' && window.innerWidth < 769) {
          const topPositionDiff = window.pageYOffset - layoutElement;
          menuElement.style.top = topPositionDiff < 0 ? `${Math.abs(topPositionDiff)}px` : '0px';
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        el.classList.remove(className);
        elHtml.classList.remove(`${className}_html`);
      }
    },
  },
});
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#mega_menu,
.sf-header__item_right {
  .sf-search-bar__input::-webkit-input-placeholder,
  .sf-search-bar__input::placeholder {
    color: #222a24;
    font-size: 14px;
    font-family: 'Spartan';
    font-weight: 500;
  }

  .sf-search-bar__input {
    padding: 0 5px;
    font-size: 14px;
    font-family: 'Spartan';
    font-weight: 500;
    border-bottom: 1px solid #222a24;
    border-radius: 0;
    width: 100%;
    min-height: 23.5px;
    height: auto;
    &:focus-visible {
      outline: none;
    }
  }
}
html.open_menu_html {
  overflow: hidden;
}
body.open_menu {
  overflow: hidden;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
body.loading_search {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.8;
  position: relative;
  top: 0;
  left: 0;
}

body.loading_search::before {
  content: '';
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #000;
  animation: rotate 0.8s infinite linear;
  -webkit-animation: rotate 0.8s infinite linear;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
}

.banner-home {
  margin-top: -94px;
}
.sf-header__logo {
  margin: 0;
}

#mega_menu {
  .sf-accordion-item__content {
    padding-bottom: 0;
    padding-left: 30px;
    padding-top: 20px;
  }
  .sf-accordion-item__header {
    padding: 0;
    line-height: 37px;
    color: #222a24;
    border: none;
    font-size: 28px;
    font-family: 'Frank Ruhl Libre';
    font-weight: 400;
  }
  .sf-accordion-item {
    margin-bottom: 18px;
  }
  .sf-accordion-item__chevron {
    display: none;
  }
  .sf-menu-item__label {
    display: block;
    line-height: 33px;
    color: #222a24;
    font-family: 'Spartan';
    font-weight: 500;
  }
  .sf-menu-item:hover {
    color: #222a24;
  }
  .sf-accordion-item__header.is-open {
    color: #222a24;
  }

  .sf-list__item a,
  .sf-list__item span {
    display: block;
    color: #222a24;
    font-family: 'Spartan';
    font-weight: 500;
    text-decoration: unset;
  }
}
// Custom Style
.sf-header-custom_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  will-change: transform;

  &.header--sticky {
    background-color: #fff;
    position: fixed;
  }
}

.sf-header-custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1414px;
  margin: 0 auto;
  padding: 24px 84px;
  .wishlist-empty-icon {
    width: 28px;
    height: 28px;
  }
}

#header_container {
  .sf-overlay {
    display: none;
  }
}

#layout #home {
  overflow: inherit;
}

.sf-header__item_left,
.sf-header__item_right {
  display: flex;
  align-items: center;
}

.sf-header--has-mobile-search .sf-header__item_right .sf-search-bar {
  margin: 0 36px 0 0;
  width: 180px;
  height: auto;
  flex: auto;
}

.sf-header__item_right .sf-header__icons {
  display: flex;
  align-items: center;
  margin: 0 14px 0 0;
}
.sf-header__cart,
.sf-header__user {
  margin: 0;
}

.sf-header__item_right .sf-image--wrapper {
  display: block;
}
.sf-header__user-image {
  img {
    width: 26px;
    object-fit: contain;
  }
}

.sf-header__wishlist-image {
  svg {
    width: 1.75rem;
    height: 1.5625rem;
  }
}

.sf-header__cart-image {
  img {
    // width: 23px;
    object-fit: contain;
  }
}

#mega_menu {
  .sf-header__list,
  .sf-search-bar {
    display: none;
  }

  .container.locales_custom {
    display: none;
  }
}

.item_icon {
  margin-right: 25px;
  img {
    display: block;
  }
}

.item_icon:last-child {
  margin-right: 0;
}

.sf-header__action_menu {
  margin-right: 42px;
}

.open_menu {
  #mega_menu {
    transform: translateX(0);
  }
  #overlay_menu {
    opacity: 1;
    visibility: visible;
  }
}

#overlay_menu {
  content: '';
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.2);
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
  visibility: hidden;
  top: 0;
  transition: all 1s;
}

#mega_menu {
  position: fixed;
  top: 0;
  height: 100%;
  background: #fdcfc1;
  width: 430px;
  padding: 32px 81px;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.5s;
  z-index: 9999;

  &.selected {
    transform: translateX(0);
  }
  &::-webkit-scrollbar {
    width: 5px;
    background: #fff;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    background-clip: padding-box;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #cccccc;
    background-clip: padding-box;
  }

  .sf-accordion-item__content {
    border: none;
  }
}

.mega_top {
  display: flex;
  align-items: center;
  margin-bottom: 68px;
  @include for-desktop {
    display: block;
  }
  .container {
    display: none;
  }
  .close_menu {
    @include for-desktop {
      float: right;
    }
    .sf-header__action_menu {
      @include for-desktop {
        margin-right: 0;
      }
    }
  }
}

.heading h3 {
  font-size: 28px;
  color: #222a24;
  line-height: 37px;
  padding-bottom: 17px;
  font-weight: 400;
}

.content {
  padding: 0;
  list-style: none;
  margin-top: 5px;
  display: none;
  padding-left: 30px;

  a {
    font-size: 12px;
    line-height: 22px;
    color: #222a24;
    font-weight: 300;
    display: inline-block;
  }
}

.locales_custom {
  .sf-list {
    flex-direction: row-reverse;
  }
}

@media (max-width: 1366px) {
  .sf-header-custom {
    padding: 31px 84px 31px;
    @include for-mobile {
      padding: 31px 24px;
    }
  }
}
@media (max-width: 1024px) {
  .sf-search-bar {
    margin: 0 24px 0 0;
  }
}
@media (max-width: 991px) {
  .sf-header__item_right .sf-header__icons {
    margin: 0;
  }
  .sf-header__list {
    display: none;
  }
  .sf-header__action_menu {
    margin-right: 25px;
  }
  .item_icon {
    margin-right: 17px;
  }
}
@media (max-width: 768px) {
  .sf-header--has-mobile-search .sf-header__search {
    display: none;
  }
  .sf-header__item_right {
    .container .sf-list {
      display: none;
    }
  }
  .mega_top .container {
    display: block;
    flex-shrink: 0;
  }
  #mega_menu {
    .sf-search-bar {
      display: block;
      flex: auto;
      margin: 0 25px 0 0;
      width: 180px;
    }
    .container .sf-list {
      margin: 0;
    }
    .sf-header__list {
      display: block;
      margin: 0 0 0 33px;
    }
    .sf-header__list li {
      font-size: 12px;
      &:last-child {
        padding-right: 0;
      }
      &:first-child {
        padding-left: 0;
      }
    }
    .sf-accordion-item__content {
      padding-top: 10px;
      padding-left: 39px;
    }
    .sf-menu-item__mobile-nav-icon {
      display: none;
    }
    .mega_top {
      .locales_custom {
        display: block;
      }
    }
  }
  //.sf-header-custom_container {
  //  transform: translateY(46px);
  //}
  .sf-header-custom {
    padding: 16px 15px 16px;
  }
  .sf-header__mail {
    display: none;
  }
  .banner-home {
    margin-top: -70px;
  }
  .sf-header__logo-image {
    width: 151px;
    display: block;
    object-fit: contain;
  }

  .sf-header__item_right {
    .sf-search-bar__input::-webkit-input-placeholder,
    .sf-search-bar__input::placeholder {
      font-size: 12px;
      font-family: 'Spartan';
      font-weight: 500;
    }
  }

  #mega_menu {
    .sf-search-bar__input::-webkit-input-placeholder,
    .sf-search-bar__input::placeholder {
      font-size: 14px;
      font-family: 'Spartan';
      font-weight: 500;
    }
  }

  .mega_top {
    margin-bottom: 33px;
  }
  #mega_menu {
    top: 46px;
    padding: 18.7px 16px 40px;

    .sf-accordion-item__header {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 9px;
      color: #000000;
    }

    .sf-menu-item__label {
      line-height: 16px;
      margin-bottom: 13px;
    }

    .sf-list__item:last-child {
      .sf-menu-item__label {
        margin-bottom: 0;
      }
    }
  }

  #mega_menu {
    &.header--sticky {
      top: 0;
    }
  }

  .close_menu {
    .sf-image {
      width: 17px;
      height: 17px;
      object-fit: contain;
    }
  }
}
@media (max-width: 430px) {
  #mega_menu {
    width: 100%;
  }
}
</style>
